
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, last, shareReplay, tap } from 'rxjs/operators';
import { GalleryService } from '../../services/gallery.service';
import { HeadService } from '../../services/head.service';
import { TitleService } from '../../services/title.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import config from '../../app.config';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  routeSubscription: Subscription;
  gallerySubscription: Subscription;
  currentPage = { code: '', title: '', description: ''};
  pagination = {
    page: 1,
    pageSize: 10,
    numberOfPages: 1,
    totalCount: 0,
    collectionUrl: '',
    pages: [],
    previousPage: 1,
    nextPage: 2,
  };

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private zone: NgZone,
    private headService: HeadService,
    private titleService: TitleService,
    private breadcrumbService: BreadcrumbService,
    public galleryService: GalleryService,
  ) { }

  ngOnInit() {
    this.subscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this._cleanState();
        this.render();
      }
    });
    this.render();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this._cleanState();
  }

  _cleanState() {
    this.routeSubscription.unsubscribe();
    this.gallerySubscription.unsubscribe();
  }

  _preparePagination(collectionUrl: string, page = 1, totalCount = 0, pageSize = 10) {
    this.pagination.page = page;
    this.pagination.pageSize = pageSize;
    this.pagination.totalCount = totalCount;
    this.pagination.numberOfPages = Math.ceil(this.pagination.totalCount / this.pagination.pageSize);
    this.pagination.collectionUrl = collectionUrl;
    this.pagination.pages = [];
    for(let i = 1; i <= this.pagination.numberOfPages; i++) {
      this.pagination.pages.push(i);
    }
    if(this.pagination.page > 1) {
      this.pagination.previousPage = this.pagination.pages[this.pagination.page - 2];
    }
    if(this.pagination.page < this.pagination.numberOfPages) {
      this.pagination.nextPage = this.pagination.pages[this.pagination.page];
    }
  }

  render(): void {
    const initialParams = this.route.snapshot.queryParams;
    let { page = 1 } = initialParams;

    this.routeSubscription = this.route.params.subscribe(({ collection, type }) => {
      page = Number(page);
      const collectionUrl = `/collection/${collection}/${type}`;
      this.currentPage = config.pages[collectionUrl];
      this.titleService.set(this.currentPage.title);
      this.headService.setMetaDescription(`${this.currentPage.description} - Irish Celtic Jewellery`);
      this.breadcrumbService.set(this.currentPage.title);
      this.getGallery(collectionUrl, page);
    });
  }

  getGallery(collectionUrl: string, page: number): void {
    this.gallerySubscription = this.galleryService.fetch(this.currentPage.code, page).subscribe((data) => {
      const { totalCount, pageSize } = data.metadata;
      this._preparePagination(collectionUrl, page, totalCount, pageSize);
    });
  }

}
