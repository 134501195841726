import { Image } from './image';
import { each as _each, split as _split } from 'lodash-es';
import { applyPriceFormatting, applyImageFormatting } from '../utils';
import HasImage from '../interfaces/HasImage';
import Thumbnail from '../interfaces/Thumbnail';

export class Product implements HasImage, Thumbnail {
  code: string;
  type: string;
  collection: string;
  slug: string;
  price: number;
  images?: Array<Image>;
  image?: Image;

  title: string;
  description: string;
  detail: string;
  onSale: boolean;
  relatedStockItems: Array<Product> = [];
  status: string;

  supplier?: string;
  originalDescription?: string;
  notes?: string;
  sex?: string;
  isPrimary?: boolean;
  galleryItemId?: number;
  availableRingSizes?: Array<string> = [];
  metadata?: any;

  // View properties:
  isRing?: boolean;
  priceInEuros?: number;
  wholeSalePrice?: number;
  wholeSalePriceInEuros?: number;
  safeProductUrl?: string;
  originalImageUrl?: string;
  safeImageUrl?: string;
  safeLargeImageUrl?: string;
  gallery?: string;
  children?: boolean;
  ladies?: boolean;
  gents?: boolean;
  unisex?: boolean;
  formattedStandardPrice?: string;
  formattedPrice?: string;

  constructor(code: string) {
    this.code = code;
  }

  static enrich(product: Product): void {
    applyImageFormatting(product);
    applyPriceFormatting(product);
    product.title = product.title || '';
    product.description = product.description || '';
    product.safeProductUrl = `/product/${product.slug}`;
    product.gallery = `${product.collection}-${product.type}`;
    _split(product.sex, ',').forEach((value) => {
      if (value !== '') {
        product[value] = true;
      }
    });

    const ringTypes = ['Ring', 'ClassicSolitaire', 'CelticEngagementRing', 'CelticWeddingBand', 'DiamondWeddingBand'];
    if(ringTypes.indexOf(product.type) > -1) {
      product.isRing = true;
    }

    product.priceInEuros = product.price / 100;
    product.wholeSalePriceInEuros = product.wholeSalePrice / 100;
    _each(product.relatedStockItems, (relatedStockItem: Product) => {
      applyImageFormatting(relatedStockItem);
      applyPriceFormatting(relatedStockItem);
      relatedStockItem.safeProductUrl = `/product/${relatedStockItem.slug}`;
    });
  }
}
