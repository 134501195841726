import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { ProductService } from '../../services/product.service';
import { TitleService } from '../../services/title.service';
import { Product } from '../../model/product';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-manage-stock-item',
  templateUrl: './manage-stock-item.component.html',
  styleUrls: ['./manage-stock-item.component.scss']
})
export class ManageStockItemComponent implements OnInit, OnDestroy {

  product$: Observable<Product>;
  safeImageUrl: string;

  subscription: Subscription;
  productSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private breadcrumbService: BreadcrumbService,
    private titleService: TitleService,
  ) {
  }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe(routeParams => {
      this.product$ = this.productService.fetchByCode(routeParams.code);
      this.productSubscription = this.product$.subscribe(product => {
        this.titleService.set(`Edit Stock Item: ${product.code}`);
        this.breadcrumbService.setGallery(product.collection, product.type);
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if(this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
  }
}
