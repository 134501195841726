export const environment = {
  production: false,
  env: 'int',
  root: 'https://test.irishcelticjewellery.com',
  api: 'https://test.irishcelticjewellery.com/api',
  image: {
    root: 'https://ik.imagekit.io/irishcelticjewellery/images/original/',
    // root: 'https://ik.imagekit.io/irishcelticjewellery/cdn/',
    thumbnail: '?tr=h-300,w-300',
    large: '?tr=h-1000,w-1000'
  },
  auth0: {
    domain: 'icj.auth0.com',
    redirectUri: 'https://test.irishcelticjewellery.com/logged-in/callback',
    clientID: 'HwzADTE2XPn1jKeNK3DDUYvle8ZDaTgZ',
    responseType: 'token id_token',
    scope: 'openid profile',
    audience: 'https://test.irishcelticjewellery.com/api/',
  },
  stripe: {
    key: 'pk_test_ynbTVwuf9T18vDo0FbUeiHl100KYWQOerB'
  }
};
