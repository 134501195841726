import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, Event, NavigationEnd, ActivatedRoute, ParamMap } from '@angular/router';
import { SearchService } from '../../services/search.service';
import { TitleService } from '../../services/title.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import config from '../../app.config';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  term: string;
  subscription: Subscription;

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private titleService: TitleService,
    private breadcrumbService: BreadcrumbService,
    public searchService: SearchService,
  ) { }

  ngOnInit() {
    this.subscription = this.route.queryParams.subscribe(params => {
      const { term } = params;
      this.term = term;
      this.render();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  render(): void {
    this.titleService.set(`Search results for: "${this.term}"`);
    this.breadcrumbService.set(`Search`);
    this.searchService.search(this.term);
  }
}
