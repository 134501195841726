import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { MobileMenuService } from './services/mobile-menu.service';
import { VersionCheckService } from './services/version-check.service';
import { CookiePolicyService } from './services/cookie-policy.service';

declare global {
  interface Window { Trustpilot: any }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  title = 'Irish Celtic Jewellery - Handcrafted with love here in Ireland';
  description = 'The finest quality irish celtic jewellery. Celtic Wedding Rings, Celtic Engagement Rings, Free Delivery.';

  constructor(
    public authService: AuthService,
    private cookiePolicyService: CookiePolicyService,
    public mobileMenuService: MobileMenuService,
    private versionCheckService: VersionCheckService) {
  }

  ngOnInit(): void {
    this.versionCheckService.initVersionCheck();
    this.cookiePolicyService.check();
  }
}
