<app-breadcrumb></app-breadcrumb>

<div class="container page">
  <div class="row">
    <div class="col-md-12">

      <div class="body">
        <div>
          <div align="center" style="text-align:center;line-height:115%;">
            <a id="_2cipo4yr3w5d" name="_2cipo4yr3w5d"></a>
            <div align="center" style="text-align: left; line-height: 150%;">
              <strong><span style="font-size: 26px;"><span class="title">RETURN POLICY</span></span></strong>
            </div>
            <div align="center" style="text-align: left; line-height: 150%;">
              <br>
            </div>
            <div align="center" style="text-align: left; line-height: 150%;">
              <span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span class="subtitle">Last
                      updated June 01, 2020</span></strong></span></span>
            </div>
            <div align="center" style="text-align: left; line-height: 150%;">
              <br>
            </div>
            <div align="center" style="text-align: left; line-height: 150%;">
              <span style="font-size: 15px;"><br>
                <a id="_2cipo4yr3w5d" name="_2cipo4yr3w5d"></a></span>
            </div>
          </div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Thank you for
              your purchase. We hope you are happy with your purchase. However, if you are not completely satisfied with
              your purchase for any reason, you may return it to us for store credit or an exchange. Please see below
              for more information on our return policy.</span>
          </div>
        </div>
        <div style="line-height: 1.1;">
          <br>
        </div>
        <div>
          <div class="heading_1" style="line-height: 115%;">
            <a id="_iwimutmowezb" name="_iwimutmowezb"></a><strong><span
                style="line-height: 115%; font-family: Arial; font-size: 19px;">RETURNS</span></strong>
          </div>
        </div>
        <div style="line-height: 1.1;">
          <br>
        </div>
        <div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">All returns
              must be postmarked within fourteen (14) days of the date they were delivered to you. All returned items must be in new and
              unused condition, with all original tags and labels attached.</span>
          </div>
        </div>
        <div style="line-height: 1.1;">
          <br>
        </div>
        <div>
          <div class="heading_1" style="line-height: 115%;">
            <a id="_16t1v96tankw" name="_16t1v96tankw"></a><strong><span
                style="line-height: 115%; font-family: Arial; font-size: 19px;">RETURN PROCESS</span></strong>
          </div>
        </div>
        <div style="line-height: 1.1;">
          <br>
        </div>
        <div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">To return an
              item, please email customer service at sales@irishcelticjewellery.com to obtain a Return Merchandise
              Authorization (RMA) number. After receiving a RMA number,&nbsp;place the item securely in its original
              packaging&nbsp;and include your proof of purchase,&nbsp;and mail your return to the following
              address:</span>
          </div>
          <div style="line-height: 1.1;">
            <span style="font-size: 15px;"><span
                style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);"><br></span></span>
          </div>
          <div class="body_text" style="line-height: 115%;">
            <span style="font-size: 15px;"><span
                style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Irish Celtic
                Jewellery<br></span></span>
          </div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;"><span
                style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Attn: Returns</span></span>
          </div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;"><span
                style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">RMA #</span></span>
          </div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">36 Oakwood Avenue</span></span> &nbsp;
          </div>
          <div class="body_text" style="line-height: 1.5;">
            Swords &nbsp;
          </div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;"><span
                style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Dublin, K67 E2A0
                &nbsp;</span></span>
          </div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;"><span
                style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Ireland
                &nbsp;</span></span>
          </div>
        </div>
        <div style="line-height: 1.1;">
          <br>
        </div>
        <div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">Please note,
              you will be responsible for all return shipping charges. We strongly recommend that you use a trackable
              method to mail your return.&nbsp;</span>
          </div>
          <div style="line-height: 1.1;">
            <br>
          </div>
          <div class="heading_1" style="line-height: 115%;">
            <a id="_qxq7t4ufn5pr" name="_qxq7t4ufn5pr"></a><strong><span
                style="line-height: 115%; font-family: Arial; font-size: 19px;">REFUNDS</span></strong>
          </div>
          <div style="line-height: 115%;">
            <a id="_kcap2nw8xg2p" name="_kcap2nw8xg2p"></a>
          </div>
          <div style="line-height: 1.1;">
            <br>
          </div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">After
              receiving your return and inspecting the condition of your item, we will process your return or exchange.
              Please allow at least seven (7) days from the receipt of your item to process your return or
              exchange.&nbsp;We will notify you by email when your return has been processed.</span>
          </div>
          <div style="line-height: 115%;">
            <a id="_gjdgxs" name="_gjdgxs"></a>
          </div>
          <div style="line-height: 1.1;">
            <br>
          </div>
          <div class="heading_1" style="line-height: 115%;">
            <a id="_33ujiidflcnn" name="_33ujiidflcnn"></a><strong><span
                style="line-height: 115%; font-family: Arial; font-size: 19px;">EXCEPTIONS &nbsp;</span></strong> <span
              style="font-size: 15px;">&nbsp;</span>
          </div>
          <div style="line-height: 1.1;">
            <br>
          </div>
        </div>
        <div class="body_text" style="line-height: 1.5;">
          <span style="font-size: 15px;"><span
              style="color: rgb(89, 89, 89); font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; float: none; display: inline !important;">The
              following items cannot be returned or exchanged:</span></span>
        </div>
        <div class="body_text" style="line-height: 1.5;">
          <span style="font-size: 15px;"><span
              style="color: rgb(89, 89, 89); font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; float: none; display: inline !important;">&nbsp;</span></span>
        </div>
        <div class="body_text" style="line-height: 1.5;">
          <strong><span style="font-size: 13px; color: rgb(89, 89, 89);">&nbsp; &nbsp; &nbsp;● &nbsp;
              &nbsp;&nbsp;</span></strong><span
            style="color: rgb(89, 89, 89); font-family: sans-serif; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">Personalized
            items, e.g. engravings&nbsp; &nbsp;</span>
        </div>
        <div class="body_text" style="line-height: 1.5;">
          <strong><span style="font-size: 13px; color: rgb(89, 89, 89);">&nbsp; &nbsp; &nbsp;● &nbsp;
              &nbsp;&nbsp;</span></strong><span
            style="color: rgb(89, 89, 89); font-family: sans-serif; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">Damaged
            items&nbsp; &nbsp;</span>
        </div>
        <div class="body_text" style="line-height: 1.1;">
          <br>
        </div>
        <div class="body_text">
          <span style="font-size: 15px;">&nbsp;</span>
        </div>
        <div class="body_text" data-empty="true" style="line-height: 1.1;">
          <span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><br></span></span>
        </div>
        <div class="body_text" style="line-height: 1.5;">
          <span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">For defective or damaged products, please
              contact us at the customer service number below to arrange a refund or exchange.&nbsp;</span></span>
        </div>
        <div class="body_text" data-empty="true" style="line-height: 1.5;">
          <span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">&nbsp;</span></span>
        </div>
        <div class="body_text" data-empty="true" style="line-height: 1.5;">
          <span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><strong>Please
                Note</strong></span></span>
        </div>
        <div class="body_text" data-empty="true" style="line-height: 1.5;">
          <span style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">&nbsp;</span></span> <span
            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">&nbsp;</span><span
              style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">&nbsp; &nbsp;</span></span></span>
        </div>
        <div class="body_text" style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);"><strong
              style="font-weight: 700; color: rgb(0, 0, 0); font-family: sans-serif; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;"><span
                style="font-size: 13px; color: rgb(89, 89, 89);">&nbsp; &nbsp; &nbsp;<strong
                  style="font-weight: 700; color: rgb(0, 0, 0); font-family: sans-serif; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial; text-decoration-color: initial; background-color: rgb(255, 255, 255);"><span
                    style="color: rgb(89, 89, 89); font-family: sans-serif; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; display: inline !important;">●&nbsp;</span></strong>&nbsp;
                &nbsp;&nbsp;</span></strong>Sale items are FINAL SALE and cannot be returned. &nbsp;&nbsp; &nbsp;</span>
        </div>
        <div style="line-height: 1.1;">
          <br>
        </div>
        <div class="heading_1">
          <span style="font-size: 19px; color: rgb(0, 0, 0);"><strong>QUESTIONS</strong></span>
        </div>
        <div style="line-height: 1.1;">
          <br>
        </div>
        <div class="body_text" style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">If you have any questions concerning our return policy,
            please contact us at:</span><span style="font-size: 15px;"></span>
        </div>
        <div class="body_text" style="line-height: 1.5;">
          <span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">+353 85 866 6215</span><span
              style="color: rgb(89, 89, 89);">&nbsp;</span></span>
        </div>
        <div class="body_text" style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">sales@irishcelticjewellery.com&nbsp;</span>
        </div>
        <style>
        </style>
      </div>

    </div>
  </div>
</div>