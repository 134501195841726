<li class="dropdown megaDropMenu" (mouseover)="menuShown=true" (mouseout)="menuShown=false">
  <span id="shop-link" class="dropdown-toggle nav-link" [ngClass]="{'shopping': shopping}">Shop</span>
  <ul class="dropdown-menu row" [hidden]="!menuShown">
    <li class="col-md-3 col-12">
      <ul class="list-unstyled">
        <li>Engagement Rings</li>
        <li><a routerLink="/collection/engagement-rings/classic-solitaire-rings" routerLinkActive="active">Solitaire
            Engagement Rings</a></li>
        <li><a routerLink="/collection/engagement-rings/celtic-engagement-rings" routerLinkActive="active">Celtic
            Engagement Rings</a></li>
        <li class="listHeading">Wedding Rings</li>
        <li><a routerLink="/collection/wedding-bands/celtic-wedding-bands" routerLinkActive="active">Celtic Wedding
            Bands</a></li>
        <li><a routerLink="/collection/wedding-bands/diamond-wedding-bands" routerLinkActive="active">Diamond Wedding
            Rings</a></li>
      </ul>
    </li>
    <li class="col-md-2 col-12">
      <ul class="list-unstyled">
        <li>Modern Irish</li>
        <li><a routerLink="/collection/modern-irish/bangles" routerLinkActive="active">Bangles</a></li>
        <li><a routerLink="/collection/modern-irish/bracelets" routerLinkActive="active">Bracelets</a></li>
        <li><a routerLink="/collection/modern-irish/chains" routerLinkActive="active">Chains</a></li>
        <li><a routerLink="/collection/modern-irish/earrings" routerLinkActive="active">Earrings</a></li>
        <li><a routerLink="/collection/modern-irish/pendants" routerLinkActive="active">Pendants</a></li>
        <li><a routerLink="/collection/modern-irish/rings" routerLinkActive="active">Rings</a></li>
        <li><a routerLink="/collection/modern-irish/watches" routerLinkActive="active">Watches</a></li>
      </ul>
    </li>
    <li class="col-md-2 col-12">
      <ul class="list-unstyled">
        <li>Celtic and Claddagh</li>
        <li><a routerLink="/collection/celtic/bangles" routerLinkActive="active">Bangles</a></li>
        <li><a routerLink="/collection/celtic/bracelets" routerLinkActive="active">Bracelets</a></li>
        <li><a routerLink="/collection/celtic/brooches" routerLinkActive="active">Brooches</a></li>
        <li><a routerLink="/collection/celtic/cufflinks" routerLinkActive="active">Cufflinks</a></li>
        <li><a routerLink="/collection/celtic/earrings" routerLinkActive="active">Earrings</a></li>
        <li><a routerLink="/collection/celtic/pendants" routerLinkActive="active">Pendants</a></li>
        <li><a routerLink="/collection/celtic/rings" routerLinkActive="active">Rings</a></li>
      </ul>
    </li>
    <li class="col-md-2 col-12">
      <ul class="list-unstyled">
        <li>House of Lor</li>
        <li><a routerLink="/collection/house-of-lor/bangles" routerLinkActive="active">Bangles</a></li>
        <li><a routerLink="/collection/house-of-lor/bracelets" routerLinkActive="active">Bracelets</a></li>
        <li><a routerLink="/collection/house-of-lor/chains" routerLinkActive="active">Chains</a></li>
        <li><a routerLink="/collection/house-of-lor/earrings" routerLinkActive="active">Earrings</a></li>
        <li><a routerLink="/collection/house-of-lor/pendants" routerLinkActive="active">Pendants</a></li>
        <li><a routerLink="/collection/house-of-lor/rings" routerLinkActive="active">Rings</a></li>
      </ul>
    </li>
    <li class="col-md-3 col-12">
      <ul class="list-unstyled" style="text-align: center"
        *ngIf="shoppingCartService.shoppingCart$ | async as shoppingCart">
        <li class="special-offer" *ngIf="shoppingCart.saleOn">{{shoppingCart.formattedSalePercent}} off! <p>selected
            items</p>
        </li>
        <li>
          <a [routerLink]="firstPromotedItem.safeProductUrl" routerLinkActive="active" class="menu-photo"><img
            [src]="firstPromotedItem.safeImageUrl" [alt]="firstPromotedItem.title"
            [appImgSrcSet]="firstPromotedItem.originalImageUrl" sizes="120px"></a>
        </li>
        <li>
          <a [routerLink]="secondPromotedItem.safeProductUrl" routerLinkActive="active" class="menu-photo"><img
            [src]="secondPromotedItem.safeImageUrl" [alt]="secondPromotedItem.title"
            [appImgSrcSet]="secondPromotedItem.originalImageUrl" sizes="120px"></a>
        </li>
      </ul>
    </li>
  </ul>
</li>