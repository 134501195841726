<app-breadcrumb></app-breadcrumb>

<section class="mainContent">
  <div class="container" *ngIf="galleryService.gallery$ | async as gallery">
    <div class="row fade-in">
      <div class="col-sm-6 offset-sm-6">
        <label class="label-right">Page: {{pagination.page}} of {{pagination.numberOfPages}}</label>
        <app-pagination [pagination]="pagination"></app-pagination>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-4 " *ngFor="let thumbnail of gallery.result; let i = index">
        <app-thumbnail [thumbnail]="thumbnail" [index]="i"></app-thumbnail>
      </div>
    </div>
    <div class="row fade-in">
      <div class="col-sm-6 offset-sm-6">
        <label class="label-right">Total: {{gallery.metadata.totalCount}}</label>
        <app-pagination [pagination]="pagination"></app-pagination>
      </div>
    </div>
  </div>
</section>