<app-breadcrumb></app-breadcrumb>

<div class="container page">
  <div class="row">
    <div class="col-md-12">
      <div class="body">
        <div align="center" class="title" style="text-align: left; line-height: 1.5;">
          <strong><span style="line-height: 22.5px; font-size: 26px;">PRIVACY NOTICE</span></strong>
        </div>
        <p><span style="color: rgb(127, 127, 127);"><strong><span class="subtitle">Last updated June 01,
                2020</span></strong></span></p>
        <p style="font-size: 15px; line-height: 1.5;"><br></p>
        <p><span><span class="body_text">Thank you for choosing to be part of our community at Jewellery Solutions ,
              doing business as Irish Celtic Jewellery (“ <strong>Irish Celtic
                Jewellery</strong> ”, “<strong>we</strong>”, “<strong>us</strong>”, or “<strong>our</strong>”). We are
              committed to protecting your personal information and your right to privacy. If you
              have any questions or concerns about this privacy notice, or our practices with regards to your personal
              information, please contact us at info@irishcelticjewellery.com.</span></span></p>
        <p><span><span class="body_text">When you visit our website <a class="link"
                href="https://www.irishcelticjewellery.com" target="_blank">https://www.irishcelticjewellery.com</a>
              (the "<strong>Website</strong>"), and more generally, use any of our services (the
              "<strong>Services</strong>", which include the
              <span><span class="body_text">Website <span><span class="body_text"></span></span></span></span></span>
            <span class="body_text"><span></span></span><span class="body_text">), we appreciate that you are trusting
              us with your personal information. We take your privacy very seriously. In this
              privacy notice, we seek to explain to you in the clearest way possible what information we collect, how
              we use it and what rights you have in relation to it. We hope you take some time to
              read through it carefully, as it is important. If there are any terms in this privacy
              notice<span>&nbsp;</span>that you do not agree with, please discontinue use of our Services
              immediately.</span></span></p>
        <p><span><span class="body_text">This privacy notice applies to all information collected through our Services
              (which, as described above, includes our Website
              <span></span></span></span> <span class="body_text">), as well as any related services, sales, marketing
            or events.</span></p>
        <p><span><strong><span class="body_text">Please read this privacy notice<span>&nbsp;</span>carefully as it
                will help you understand what we do with the information that we
                collect.</span></strong></span></p>
        <p style="font-size: 15px; line-height: 1.5;"><span><br></span></p>
        <p><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span class="heading_1">TABLE OF
                  CONTENTS</span></span></strong></span></p>
        <p><a class="link" href="/privacy#infocollect"><span>1. WHAT INFORMATION DO WE COLLECT?</span></a> <span></span>
        </p>
        <p><a class="link" href="#infouse"><span>2. HOW DO WE USE YOUR INFORMATION?</span></a>
          <span><span></span></span></p>
        <p><span><a class="link" href="#infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></span></p>
        <p><span><a class="link" href="#whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
            <span></span></span> <span style="font-size: 15px; color: rgb(89, 89, 89);"></span></p>
        <p><a class="link" href="#cookies"><span style="font-size: 15px; color: rgb(89, 89, 89);">5. DO WE USE COOKIES
              AND OTHER TRACKING TECHNOLOGIES?</span></a> <span style="font-size: 15px; color: rgb(89, 89, 89);"></span>
          <span><span></span></span></p>
        <p><a class="link" href="#sociallogins"><span><span><span>6. HOW DO WE HANDLE YOUR SOCIAL
                  LOGINS?</span></span></span></a> <span><span><span></span></span></span></p>
        <p><a class="link" href="#intltransfers"><span>7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></a>
          <span></span></p>
        <p><a class="link" href="#inforetain"><span>8. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a>
          <span><span></span></span></p>
        <p><a class="link" href="#infosafe"><span>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a>
          <span><span><span></span></span></span></p>
        <p><a class="link" href="#infominors"><span>10. DO WE COLLECT INFORMATION FROM MINORS?</span></a></p>
        <p><a class="link" href="#privacyrights"><span>11. WHAT ARE YOUR PRIVACY RIGHTS?</span></a></p>
        <p><a class="link" href="#DNT"><span>12. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></p>
        <p><a class="link" href="#caresidents"><span>13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
              RIGHTS?</span></a></p>
        <p><a class="link" href="#policyupdates"><span>14. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></p>
        <p><a class="link" href="#contact"><span>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></p>
        <p style="font-size: 15px; line-height: 1.5;"><span><br></span></p>
        <p id="infocollect"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                  class="heading_1">1. WHAT INFORMATION DO WE
                  COLLECT?</span></span></strong></span></p>
        <p><strong><span style="font-size: 16px;"><span class="heading_2"><strong><span class="heading_2"><br>
                    Personal information you disclose to us</span></strong></span></span></strong></p>
        <p><strong><span class="body_text"><em><span>In Short: &nbsp;</span></em></span></strong><span
            class="body_text"><em><span>We collect information that you provide to
                us.</span></em></span></p>
        <p><span class="body_text"><span>We collect personal information that you voluntarily provide to us when you
              register on the Website, <span class="body_text"><span></span></span>express an interest in obtaining
              information about us or our products and Services, when you participate in activities on the <span
                class="body_text"><span>Website</span></span> or otherwise when you contact us.</span></span></p>
        <p><span class="body_text"><span>The personal information that we collect depends on the context of your
              interactions with us and the <span class="body_text">Website</span> , the choices you make and the
              products and features you use. The personal information we collect may include the
              following:</span></span></p>
        <p><span><span class="body_text"><strong>Personal Information Provided by You.</strong> We collect names;
              phone numbers; email addresses; mailing addresses; billing addresses;
              usernames; and other similar information.</span> <span class="body_text"></span></span></p>
        <p><span><strong><span class="body_text">Payment Data.&nbsp;</span></strong><span class="body_text">We may
              collect data necessary to process your payment if you make
              purchases, such as your payment instrument number (such as a credit card number), and the security code
              associated with your payment instrument. All payment data is stored by
              <span><span class="body_text"><span><span class="body_text">Stripe <span><span
                          class="body_text"><span><span class="body_text">and
                              <span><span class="body_text"><span><span class="body_text"><span><span
                                          class="body_text"><span><span
                                              class="body_text">Paypal</span></span></span></span></span></span></span></span></span></span>
                          . You may find their privacy notice link(s) here: <span><a class="link"
                              href="https://stripe.com/ie/privacy" target="_blank">https://stripe.com/ie/privacy</a>
                            <span><span class="body_text"><span>and <span><span class="body_text"><span><span><span
                                            class="body_text"><span><a class="link"
                                                href="https://www.paypal.com/va/webapps/mpp/ua/privacy-full"
                                                target="_blank">https://www.paypal.com/va/webapps/mpp/ua/privacy-full</a></span></span></span></span></span></span></span></span></span></span>
                          <span class="body_text">.</span>
                          <span class="body_text"></span></span></span></span></span></span></span></span></span></p>
        <p><span><span class="body_text"><strong>Social Media Login Data.</strong> We may provide you with the option
              to register with us using your existing social media account
              details, like your Facebook, Twitter or other social media account. If you choose to register in this
              way, we will collect the information described in the section called
              "<span><span><a class="link" href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>"
                  below.</span></span></span></span> <span class="body_text"><span></span></span></p>
        <div>
          <span class="body_text">All personal information that you provide to us must be true, complete and accurate,
            and you must notify us of any changes to such personal
            information. <span></span></span>
        </div>
        <p><strong><span style="font-size: 16px;"><span class="heading_2"><strong><span class="heading_2"><br>
                    Information automatically collected</span></strong></span></span></strong></p>
        <div>
          <span class="body_text"><em><strong>In Short: &nbsp;</strong>Some information — such as your Internet
              Protocol (IP) address and/or browser and device characteristics — is
              collected automatically when you visit our <span class="body_text"><span><span
                    class="body_text">Website</span></span></span> .</em></span>
        </div>
        <div>
          <br>
        </div>
        <div>
          <span class="body_text">We automatically collect certain information when you visit, use or navigate the
            <span><span class="body_text">Website</span></span> . This
            information does not reveal your specific identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and device
            characteristics, operating system, language preferences, referring URLs, device name, country, location,
            information about who and when you use our <span><span class="body_text">Website</span></span> and other
            technical information. This information is primarily needed to maintain the security and operation of our
            <span><span class="body_text">Website</span></span> , and for our internal analytics and reporting
            purposes.</span>
        </div>
        <div>
          <br>
        </div>
        <div>
          <span class="body_text">Like many businesses, we also collect information through cookies and similar
            technologies.</span> &nbsp;
        </div>
        <div>
          <br>
        </div>
        <div>
          <span class="body_text">The information we collect includes:</span>
        </div>
        <ul>
          <li><span><span><span class="body_text"><em>Log and Usage Data.</em> Log and usage data is service-related,
                  diagnostic usage and performance information our servers
                  automatically collect when you access or use our <span><span class="body_text">Website</span></span>
                  and which we record in log files. Depending on how you interact with us,
                  this log data may include your IP address, device information, browser type and settings and
                  information about your activity in the <span><span class="body_text">Website</span></span> (such as
                  the date/time stamps associated with your usage, pages and files viewed, searches and other actions
                  you take such as which features you use),
                  device event information (such as system activity, error reports (sometimes called 'crash dumps')
                  and hardware settings).</span></span></span> <span><span><span
                  class="body_text"><span><span></span></span></span></span></span></li>
        </ul>
        <div></div>
        <ul>
          <li><span class="body_text">
              <font color="#595959"><span><em>Device Data.&nbsp;</em>We collect device data such as information about
                  your computer, phone, tablet or other
                  device you use to access the&nbsp;</span></font> <span><span class="body_text">Website</span></span>
              <font color="#595959"><span>. Depending on the device used, this device
                  data may include information such as your IP address (or proxy server), device application
                  identification numbers, location, browser type, hardware model Internet service provider and/or
                  mobile carrier, operating system configuration&nbsp;information.</span></font>
            </span> <span><span><span class="body_text"><span><span></span></span></span></span></span></li>
        </ul>
        <div></div>
        <ul>
          <li><span><span><span class="body_text"><em>Location Data.</em> We collect information data such as
                  information about your device's location, which can be either precise or
                  imprecise. How much information we collect depends on the type of settings of the device you use to
                  access the <span><span class="body_text">Website</span></span> . For
                  example, we may use GPS and other technologies to collect geolocation data that tells us your
                  current location (based on your IP address). You can opt out of allowing us to collect this
                  information either by refusing access to the information or by disabling your Locations settings on
                  your device. Note however, if you choose to opt out, you may not be able to use certain
                  aspects of the Services.</span></span></span> <span><span><span
                  class="body_text"><span><span></span></span></span></span></span></li>
        </ul>
        <div>
          <span class="body_text"><span><span><span class="body_text"><span><span
                      class="body_text"></span></span></span></span> <span
                style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text"><span
                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                      class="body_text"></span></span></span></span></span></span>
          <p></p>
          <p style="font-size: 15px; line-height: 1.5;"><br></p>
          <p id="infouse"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                    class="heading_1">2. HOW DO WE USE YOUR
                    INFORMATION?</span></span></strong></span></p>
          <p><span class="body_text"><em><strong>In Short: &nbsp;</strong>We process your information for purposes
                based on legitimate business interests, the fulfillment of our
                contract with you, compliance with our legal obligations, and/or your consent.</em></span></p>
          <p><span class="body_text">We use personal information collected via our <span><span
                  class="body_text">Website</span></span> for a variety of business purposes
              described below. We process your personal information for these purposes in reliance on our legitimate
              business interests, in order to enter into or perform a contract with you, with your
              consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.</span></p>
          <p><span class="body_text">We use the information we collect or receive:</span></p>
          <ul>
            <li><span class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span><span><strong><span
                          class="body_text">To
                          facilitate account creation and
                          logon process.</span></strong><span class="body_text">&nbsp;If you choose to link your
                        account with us to a third-party account (such as your Google or Facebook account), we
                        use the information you allowed us to collect from those third parties to facilitate account
                        creation and logon process for the performance of the contract. See the section below headed
                        "</span></span></span><span class="body_text"><a class="link"
                      href="#sociallogins"><span><span>HOW DO WE HANDLE YOUR SOCIAL
                          LOGINS</span></span></a></span><span><span><span class="body_text">" for further
                        information. <span><span><span
                              class="body_text"></span></span></span></span></span></span></span><br>
                <br></span></li>
            <li><span><span><strong><span class="body_text">To post testimonials.</span></strong><span
                    class="body_text">&nbsp;We post testimonials on our
                    <span><span><span class="body_text">Website</span></span></span> that may contain personal
                    information. Prior to posting a testimonial, we will obtain your consent to use
                    your name and the consent of the testimonial. If you wish to update, or delete your testimonial,
                    please contact us at alan@irishcelticjewellery.com and be sure to include your name, testimonial
                    location, and contact information.</span></span></span> <span class="body_text"><br>
                <br></span></li>
            <li><span><span><strong><span class="body_text">Request feedback.</span></strong><span
                    class="body_text">&nbsp;We may use your information to request feedback
                    and to contact you about your use of our <span><span><span
                          class="body_text">Website</span></span></span> .</span></span></span><span
                class="body_text"><br>
                <br></span></li>
            <li><span><span><strong><span class="body_text">To enable user-to-user
                      communications.</span></strong><span class="body_text">&nbsp;We may use your information
                    in order to enable user-to-user communications with each user's consent.</span></span></span><span
                class="body_text"><br>
                <br></span></li>
            <li><span class="body_text"><strong>To manage user accounts</strong>. We may use your information for the
                purposes of managing our account and keeping it in working
                order.</span></li>
          </ul>
          <p></p>
          <p></p>
          <ul>
            <li><span><span><strong><span class="body_text">Fulfill and manage your orders.</span></strong><span
                    class="body_text">&nbsp;We may use your information to
                    fulfill and manage your orders, payments, returns, and exchanges made through the
                    <span><span><span class="body_text">Website</span></span></span>
                    .</span></span></span><span class="body_text"><br>
                <br></span></li>
            <li><span><span><strong><span class="body_text">Administer prize draws and
                      competitions.&nbsp;</span></strong><span class="body_text">We may use your information
                    to administer prize draws and competitions when you elect to participate in our
                    competitions.</span></span></span><span class="body_text"><br>
                <br></span></li>
            <li><span class="body_text"><strong>To deliver and facilitate delivery of services to the user.</strong>
                We may use your information to provide you with the requested
                service.</span></li>
            <li><span class="body_text"><strong>To respond to user inquiries/offer support to users.</strong> We may
                use your information to respond to your inquiries and solve any
                potential issues you might have with the use of our Services.</span></li>
          </ul>
          <p></p>
          <ul>
            <li><span><span><strong><span class="body_text">To send you marketing and promotional
                      communications.</span></strong><span class="body_text">&nbsp;We and/or our
                    third-party marketing partners may use the personal information you send to us for our marketing
                    purposes, if this is in accordance with your marketing preferences. For example, when
                    expressing an interest in obtaining information about us or our <span><span><span
                          class="body_text">Website</span></span></span> , subscribing to marketing or otherwise
                    contacting us, we will collect personal information from you. You can opt-out of our marketing
                    emails at any time (see the "</span></span></span><span class="body_text"><a class="link"
                  href="#privacyrights"><span><span>WHAT ARE YOUR PRIVACY
                      RIGHTS</span></span></a></span><span><span><span class="body_text">"
                    below).</span></span></span><span class="body_text"><br>
                <br></span></li>
            <li><span><span><strong><span class="body_text">Deliver targeted advertising to
                      you.&nbsp;</span></strong><span class="body_text">We may use your information to
                    develop and display personalized content and advertising (and work with third parties who do so)
                    tailored to your interests and/or location and to measure its
                    effectiveness.</span></span></span></li>
          </ul>
          <p></p>
          <p></p>
          <p><br></p>
          <p id="infoshare"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                    class="heading_1">3. WILL YOUR INFORMATION BE SHARED WITH
                    ANYONE?</span></span></strong></span></p>
          <p><span><strong><em><span><span class="body_text">In
                      Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span class="body_text">We only share
                    information
                    with your consent, to comply with laws, to provide you with services, to protect your rights, or
                    to fulfill business obligations.</span></em></span></span></p>
          <div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">We may process or share
                your data that we hold based on the following legal
                basis:</span></span>
          </div>
          <ul>
            <li><span class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Consent:</strong>
                  We may process your data
                  if you have given us specific consent
                  to use your personal information in a specific purpose.</span><br>
                <br></span></li>
            <li><span class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Legitimate
                    Interests:</strong> We may process your data when it is reasonably
                  necessary to achieve our legitimate business interests.</span><br>
                <br></span></li>
            <li><span class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Performance of
                    a Contract:&nbsp;</strong>Where we have entered into a contract
                  with you, we may process your personal information to fulfill the terms of our contract.</span><br>
                <br></span></li>
            <li><span class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Legal
                    Obligations:</strong> We may disclose your information where we are legally
                  required to do so in order to comply with applicable law, governmental requests, a judicial
                  proceeding, court order, or legal process, such as in response to a court order or a subpoena
                  (including in response to public authorities to meet national security or law enforcement
                  requirements).</span><br>
                <br></span></li>
            <li><span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text"><strong>Vital
                    Interests:</strong> We may disclose your information where we believe it is
                  necessary to investigate, prevent, or take action regarding potential violations of our policies,
                  suspected fraud, situations involving potential threats to the safety of any person and
                  illegal activities, or as evidence in litigation in which we are involved.</span></span></li>
          </ul>
          <p><span><span><span class="body_text">More specifically, we may need to process your data or share your
                  personal information in the following
                  situations:</span></span></span></p>
          <ul>
            <li><span><span><strong><span class="body_text">Business Transfers.</span></strong><span
                    class="body_text">&nbsp;We may share or transfer your information in
                    connection with, or during negotiations of, any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.</span></span></span></li>
          </ul>
          <div>
            <span><span></span></span>
          </div>
          <ul>
            <li><span><span><strong><span class="body_text">Vendors, Consultants and Other Third-Party Service
                      Providers.</span></strong><span class="body_text">&nbsp;We may
                    share your data with third-party vendors, service providers, contractors or agents who perform
                    services for us or on our behalf and require access to such information to do that work.
                    Examples include: payment processing, data analysis, email delivery, hosting services, customer
                    service and marketing efforts. We may allow selected third parties to use tracking
                    technology on the <span><span><span class="body_text">Website</span></span></span> , which will
                    enable them to collect data on our behalf about how you interact with our
                    <span><span><span class="body_text">Website</span></span></span> over time. This information may
                    be used to, among other things, analyze and track data, determine the
                    popularity of certain content, pages or features, and better understand online activity. Unless
                    described in this notice, we do not share, sell, rent or trade any of your information
                    with third parties for their promotional purposes. We have contracts in place with our data
                    processors, which are designed to help safegaurd your personal information. This means that
                    they cannot do anything with your personal information unless we have instructed them to do it.
                    They will also not share your personal information with any organization apart from us.
                    They also commit to protect the data they hold on our behalf and to retain it for the period we
                    instruct. <span><span><span class="body_text"></span></span></span></span></span></span>
              <span><span><span class="body_text"><span><span></span></span></span></span></span></li>
          </ul>
          <div>
            <span><span></span></span> <span><span><span class="body_text"><span><span><span
                        class="body_text"><span><span></span></span></span></span></span></span></span></span>
            <div>
              <span><span><span><span></span></span></span></span>
              <div>
                <span><span></span></span>
                <div>
                  <span><span></span></span>
                  <div>
                    <span><span><span></span></span></span>
                    <div>
                      <span></span>
                      <p style="font-size: 15px; line-height: 1.5;"><br></p>
                      <p id="whoshare"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                class="heading_1">4. WHO WILL YOUR INFORMATION BE SHARED
                                WITH?</span></span></strong></span></p>
                    </div>
                    <div>
                      <span><strong><em><span><span class="body_text">In
                                Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span class="body_text">We
                              only share
                              information with the following third parties.</span></em></span></span>
                    </div>
                    <div>
                      <span><span>&nbsp;</span>&nbsp;</span>
                    </div>
                    <div>
                      <span><span><span class="body_text">We only share and disclose your information with the
                            following third parties. We have categorized each party so that you may
                            easily understand the purpose of our data collection and processing practices. If we have
                            processed your data based on your consent and you wish to revoke your consent, please
                            contact us using the contact details provided in the section below titled
                            "</span></span></span><a class="link" href="#contact"><span>HOW CAN YOU CONTACT US ABOUT
                          THIS NOTICE?</span></a><span><span><span class="body_text">".</span></span></span>
                      <div>
                        <div></div>
                        <ul>
                          <li><span><span><span class="body_text"><strong>Allow Users to Connect to Their Third-Party
                                    Accounts</strong></span></span></span><br>
                            <span><span><span class="body_text"><span><span>Google
                                      account</span></span></span></span></span> <span
                              class="body_text"><span><span>,<span><span>&nbsp;Facebook
                                      account</span></span></span></span></span> <span
                              class="body_text"><span><span></span></span> and Twitter
                              account <span><span><span><span></span></span></span></span></span></li>
                        </ul>
                        <div>
                          <div></div>
                          <ul>
                            <li><span><span><span class="body_text"><strong>Communicate and Chat with
                                      Users</strong></span></span></span><br>
                              <span><span><span class="body_text"><span><span>Mailgun</span></span></span></span></span>
                              <span class="body_text"><span><span></span></span> and
                                Chatra <span><span><span><span></span></span></span></span></span></li>
                          </ul>
                          <div></div>
                          <ul>
                            <li><span><span><span class="body_text"><strong>Content
                                      Optimization</strong></span></span></span><br>
                              <span><span><span class="body_text"><span><span>YouTube video
                                        embed</span></span></span></span></span></li>
                          </ul>
                          <div>
                            <div>
                              <div></div>
                              <ul>
                                <li><span><span><span class="body_text"><strong>Invoice and
                                          Billing</strong></span></span></span><br>
                                  <span><span><span
                                        class="body_text"><span><span>Stripe</span></span></span></span></span> <span
                                    class="body_text"><span><span></span></span>
                                    and PayPal <span><span><span><span></span></span></span></span></span></li>
                              </ul>
                              <div></div>
                              <ul>
                                <li><span><span><span class="body_text"><strong>Retargeting
                                          Platforms</strong></span></span></span><br>
                                  <span><span><span class="body_text"><span><span>Facebook
                                            Remarketing</span></span></span></span></span> <span
                                    class="body_text"><span><span></span></span> and Google Ads Remarketing
                                    <span><span><span><span></span></span></span></span></span></li>
                              </ul>
                              <div></div>
                              <ul>
                                <li><span><span><span class="body_text"><strong>Social Media Sharing and
                                          Advertising</strong></span></span></span><br>
                                  <span><span><span class="body_text"><span><span>Twitter social
                                            plugins</span></span></span></span></span> <span
                                    class="body_text"><span><span>,<span><span>&nbsp;Facebook social
                                            plugins</span></span></span></span></span> <span
                                    class="body_text"><span><span></span></span>
                                    and Instagram advertising
                                    <span><span><span><span></span></span></span></span></span></li>
                              </ul>
                              <div></div>
                              <ul>
                                <li><span><span><span class="body_text"><strong>User Account Registration and
                                          Authentication</strong></span></span></span><br>
                                  <span><span><span
                                        class="body_text"><span><span>Auth0</span></span></span></span></span></li>
                              </ul>
                              <div></div>
                              <ul>
                                <li><span><span><span class="body_text"><strong>User Commenting and
                                          Forums</strong></span></span></span><br>
                                  <span><span><span
                                        class="body_text"><span><span>Ghost</span></span></span></span></span></li>
                              </ul>
                              <div></div>
                              <ul>
                                <li><span><span><span class="body_text"><strong>Web and Mobile
                                          Analytics</strong></span></span></span><br>
                                  <span><span><span class="body_text"><span><span>Google
                                            Analytics</span></span></span></span></span></li>
                              </ul>
                              <div>
                                <span style="color: rgb(89, 89, 89); font-size: 15px;"><span><span><span><span><span
                                            class="heading_1"></span></span></span></span></span></span>
                              </div>
                              <p><br></p>
                              <div>
                                <span id="cookies" style="color: rgb(0, 0, 0);"><strong><span
                                      style="font-size: 19px;"><span class="heading_1">5. DO WE USE COOKIES AND OTHER
                                        TRACKING
                                        TECHNOLOGIES?</span></span></strong></span>
                              </div>
                              <p><span><strong><em><span><span class="body_text">In
                                          Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span
                                        class="body_text">We may
                                        use cookies and other tracking technologies to collect and store your
                                        information.</span></em></span></span></p>
                              <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">We may
                                    use cookies and similar tracking technologies (like web beacons
                                    and pixels) to access or store information. Specific information about how we use
                                    such technologies and how you can refuse certain cookies is set out in our Cookie
                                    Notice .</span> <span
                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span><span><span><span><span
                                              class="body_text"></span> <span
                                              class="body_text"></span></span></span></span></span></span></span></p>
                              <p style="font-size: 15px; line-height: 1.5;"><span><br></span></p>
                              <p id="sociallogins"><span style="color: rgb(0, 0, 0);"><strong><span
                                      style="font-size: 19px;"><span class="heading_1">6. HOW DO WE HANDLE YOUR SOCIAL
                                        LOGINS?</span></span></strong></span></p>
                              <p><span><strong><em><span><span class="body_text">In
                                          Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span
                                        class="body_text">If you
                                        choose to register or log in to our services using a social media account, we
                                        may have access to certain information about you.</span></em></span></span>
                              </p>
                              <p><span><span><span class="body_text">Our <span><span><span
                                            class="body_text"><span><span><span
                                                  class="body_text">Website</span></span></span></span></span></span>
                                      offers you the ability to register and login using your third-party social media
                                      account details (like
                                      your Facebook or Twitter logins). Where you choose to do this, we will receive
                                      certain profile information about you from your social media provider. The
                                      profile
                                      Information we receive may vary depending on the social media provider
                                      concerned, but will often include your name, email address, friends list,
                                      profile picture as
                                      well as other information you choose to make public on such social media
                                      platform.</span></span></span></p>
                              <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">We
                                    will use the information we receive only for the purposes that are
                                    described in this privacy notice<span>&nbsp;</span>or that are otherwise made
                                    clear to you on the relevant <span><span><span class="body_text"><span><span><span
                                                class="body_text">Website</span></span></span></span></span></span> .
                                    Please note that we do not control, and are not
                                    responsible for, other uses of your personal information by your third-party
                                    social media provider. We recommend that you review their privacy notice to
                                    understand how
                                    they collect, use and share your personal information, and how you can set your
                                    privacy preferences on their sites and apps.</span> <span
                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                      style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span><span><span><span><span
                                                  class="body_text"></span> <span
                                                  class="body_text"></span></span></span></span></span></span></span></span></span>
                              </p>
                              <p style="font-size: 15px; line-height: 1.5;"><span><br></span></p>
                              <p id="intltransfers"><span style="color: rgb(0, 0, 0);"><strong><span
                                      style="font-size: 19px;"><span class="heading_1">7. IS YOUR INFORMATION
                                        TRANSFERRED
                                        INTERNATIONALLY?</span></span></strong></span></p>
                              <p><span><strong><em><span><span class="body_text">In
                                          Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span
                                        class="body_text">We may
                                        transfer, store, and process your information in countries other than your
                                        own.</span></em></span></span></p>
                              <p><span><span><span class="body_text">Our servers are located in United States . If you
                                      are accessing our <span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                          class="body_text"><span><span><span class="body_text"><span><span><span
                                                      class="body_text">Website</span></span></span></span></span></span></span></span>
                                      from outside United States , please be aware that your information may be
                                      transferred to,
                                      stored, and processed by us in our facilities and by those third parties with
                                      whom we may share your personal information (see "</span></span></span>
                                <span class="body_text"><a class="link" href="#infoshare"><span><span>WILL YOUR
                                        INFORMATION BE SHARED WITH
                                        ANYONE?</span></span></a></span><span><span><span class="body_text">" above),
                                      in and other countries.</span></span></span></p>
                              <p><span><span><span class="body_text">If you are a resident in the European Economic
                                      Area, then these countries may not necessarily have data protection
                                      laws or other similar laws as comprehensive as those in your country. We will
                                      however take all necessary measures to protect your personal information in
                                      accordance
                                      with this privacy notice<span>&nbsp;</span>and applicable
                                      law.</span></span></span></p>
                              <p><span><span><span class="body_text">European Commission's Standard Contractual
                                      Clauses:</span></span></span></p>
                              <p><span><span><span class="body_text">We have implemented measures to protect your
                                      personal information, including by using the European Commission's
                                      Standard Contractual Clauses for transfers of personal information between our
                                      group companies and between us and our third-party providers. These clauses
                                      require all
                                      recipients to protect all personal information that they process originating
                                      from the EEA in accordance with European data protection laws and regulations.
                                      Our
                                      Standard Contractual Clauses can be provided upon request. We have implemented
                                      similar appropriate safeguards with our third-party service providers and
                                      partners and
                                      further details can be provided upon request. <span><span><span
                                            class="body_text"></span></span></span></span></span></span> <span
                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                      style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span><span><span><span><span
                                                  class="body_text"></span></span></span></span></span></span></span></span></span>
                              </p>
                              <p style="font-size: 15px; line-height: 1.5;"><span><br></span></p>
                              <p id="inforetain"><span style="color: rgb(0, 0, 0);"><strong><span
                                      style="font-size: 19px;"><span class="heading_1">8. HOW LONG DO WE KEEP YOUR
                                        INFORMATION?</span></span></strong></span></p>
                              <p><span><strong><em><span><span class="body_text">In
                                          Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span
                                        class="body_text">We keep
                                        your information for as long as necessary to fulfill the purposes outlined in
                                        this privacy notice<span>&nbsp;</span>unless otherwise required by
                                        law.</span></em></span></span></p>
                              <p><span><span><span class="body_text">We will only keep your personal information for
                                      as long as it is necessary for the purposes set out in this privacy
                                      notice, unless a longer retention period is required or permitted by law (such
                                      as tax, accounting or other legal requirements). No purpose in this notice will
                                      require
                                      us keeping your personal information for longer than</span></span></span> <span
                                  class="body_text">the period of time in which users have an account with
                                  us</span> <span><span><span class="body_text">.</span></span></span></p>
                              <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">When
                                    we have no ongoing legitimate business need to process your personal
                                    information, we will either delete or anonymize such information, or, if this is
                                    not possible (for example, because your personal information has been stored in
                                    backup
                                    archives), then we will securely store your personal information and isolate it
                                    from any further processing until deletion is possible.</span></span>
                                <span></span></p>
                              <div style="line-height: 1.5;">
                                <br>
                              </div>
                              <p id="infosafe"><span style="color: rgb(0, 0, 0);"><strong><span
                                      style="font-size: 19px;"><span class="heading_1">9. HOW DO WE KEEP YOUR
                                        INFORMATION
                                        SAFE?</span></span></strong></span></p>
                              <p><span><strong><em><span><span class="body_text">In
                                          Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span
                                        class="body_text">We aim to
                                        protect your personal information through a system of organizational and
                                        technical security measures.</span></em></span></span></p>
                              <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">We
                                    have implemented appropriate technical and organizational security
                                    measures designed to protect the security of any personal information we process.
                                    However, despite our safeguards and efforts to secure your information, no
                                    electronic
                                    transmission over the Internet or information storage technology can be guaranteed
                                    to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals,
                                    or other unauthorized third parties will not be able to defeat our security, and
                                    improperly collect, access, steal, or modify your information. Although we will do
                                    our
                                    best to protect your personal information, transmission of personal information to
                                    and from our <span><span><span class="body_text"><span><span><span
                                                class="body_text">Website</span></span></span></span></span></span> is
                                    at your own risk. You should only access the
                                    <span><span><span class="body_text"><span><span><span
                                                class="body_text">Website</span></span></span></span></span></span>
                                    within a secure
                                    environment.</span></span> <span></span> <span
                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                    class="body_text"></span></span></p>
                              <p style="font-size: 15px; line-height: 1.5;"><span><br></span></p>
                              <p id="infominors"><span style="color: rgb(0, 0, 0);"><strong><span
                                      style="font-size: 19px;"><span class="heading_1">10. DO WE COLLECT INFORMATION
                                        FROM
                                        MINORS?</span></span></strong></span></p>
                              <p><span><strong><em><span><span class="body_text">In
                                          Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span
                                        class="body_text">We do not
                                        knowingly collect data from or market to children under 18 years of
                                        age.</span></em></span></span></p>
                              <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">We do
                                    not knowingly solicit data from or market to children under 18
                                    years of age. By using the <span><span><span class="body_text"><span><span><span
                                                class="body_text">Website</span></span></span></span></span></span> ,
                                    you represent that you are at least 18 or that you are the parent or guardian of
                                    such a minor and
                                    consent to such minor dependent’s use of the <span><span><span
                                          class="body_text"><span><span><span
                                                class="body_text">Website</span></span></span></span></span></span> .
                                    If we learn that personal information from users less than 18 years of age has
                                    been collected, we will
                                    deactivate the account and take reasonable measures to promptly delete such data
                                    from our records. If you become aware of any data we may have collected from
                                    children
                                    under age 18, please contact us at privacy@irishcelticjewellery.com .</span> <span
                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                      class="body_text"></span></span></span></p>
                              <p style="font-size: 15px; line-height: 1.5;"><span><br></span></p>
                              <p id="privacyrights"><span style="color: rgb(0, 0, 0);"><strong><span
                                      style="font-size: 19px;"><span class="heading_1">11. WHAT ARE YOUR PRIVACY
                                        RIGHTS?</span></span></strong></span></p>
                              <p><span><strong><em><span><span class="body_text">In
                                          Short:</span></span>&nbsp;</em>&nbsp;</strong> <span><span
                                      class="body_text"><em>In some
                                        regions, such as the European Economic Area, you have rights that allow you
                                        greater access to and control over your personal information. You may review,
                                        change, or
                                        terminate your account at any time.</em></span></span></span></p>
                              <p><span><span><span class="body_text">In some regions (like the European Economic
                                      Area), you have certain rights under applicable data protection laws.
                                      These may include the right (i) to request access and obtain a copy of your
                                      personal information, (ii) to request rectification or erasure; (iii) to
                                      restrict the
                                      processing of your personal information; and (iv) if applicable, to data
                                      portability. In certain circumstances, you may also have the right to object to
                                      the processing
                                      of your personal information. To make such a request, please use
                                      the</span></span>&nbsp;</span><span class="body_text"><a class="link"
                                    href="#contact"><span><span style="color: rgb(48, 48, 241);">contact
                                        details</span></span></a></span><span><span><span
                                      class="body_text">&nbsp;provided below.
                                      We will consider and act upon any request in accordance with applicable data
                                      protection laws.</span></span></span></p>
                              <p><span><span><span class="body_text">If we are relying on your consent to process your
                                      personal information, you have the right to withdraw your consent
                                      at any time. Please note however that this will not affect the lawfulness of the
                                      processing before its withdrawal, nor will it affect the processing of your
                                      personal
                                      information conducted in reliance on lawful processing grounds other than
                                      consent. <span><span></span></span></span></span></span></p>
                              <p><span><span><span class="body_text">If you are resident in the European Economic Area
                                      and you believe we are unlawfully processing your personal
                                      information, you also have the right to complain to your local data protection
                                      supervisory authority. You can find their contact details
                                      here:<span>&nbsp;</span><span style="color: rgb(48, 48, 241);"><span
                                          class="body_text"><a class="link"
                                            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                            rel="noopener noreferrer"
                                            target="_blank"><span>http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a></span></span></span></span></span><span
                                  class="body_text"><span style="color: rgb(48, 48, 241);"><span>.</span></span></span>
                              </p>
                              <p><span><span><span class="body_text">If you are resident in Switzerland, the contact
                                      details for the data protection authorities are available here:
                                      <span style="color: rgb(48, 48, 241);"><span class="body_text"><span><a
                                              class="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                              rel="noopener noreferrer"
                                              target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</span></span></span></span></span></span>
                              </p>
                              <p><span><span><span class="body_text"></span></span></span></p>
                              <p><span><span><span class="body_text">If you have questions or comments about your
                                      privacy rights, you may email us at info@irishcelticjewellery.com
                                      .</span></span></span></p>
                              <div style="line-height: 1.1;">
                                <br>
                              </div>
                              <div>
                                <span style="font-size: 16px;"><span style="color: rgb(0, 0, 0);"><strong><span
                                        class="heading_2">Account Information</span></strong></span></span>
                              </div>
                              <p><span><span><span class="body_text">If you would at any time like to review or change
                                      the information in your account or terminate your account, you
                                      can:</span></span></span></p>
                              <p>&nbsp; &nbsp; ■ &nbsp;<span><span><span class="body_text">Contact us using the
                                      contact information provided.</span></span></span></p>
                              <p><span><span><span class="body_text">Upon your request to terminate your account, we
                                      will deactivate or delete your account and information from our
                                      active databases. However, we may retain some information in our files to
                                      prevent fraud, troubleshoot problems, assist with any investigations, enforce
                                      our Terms of
                                      Use and/or comply with applicable legal requirements.</span> <span><span><span
                                          class="body_text"><span><span></span></span></span></span></span></span></span>
                              </p>
                              <p><span><span><strong><u><span class="body_text">Cookies and similar
                                          technologies:</span></u>&nbsp;</strong><span class="body_text">Most Web
                                      browsers are set to accept cookies by default. If you prefer, you can usually
                                      choose to set your browser to remove cookies and to reject cookies. If you
                                      choose to
                                      remove cookies or reject cookies, this could affect certain features or services
                                      of our <span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                          class="body_text"><span><span><span class="body_text"><span><span><span
                                                      class="body_text">Website</span></span></span></span></span></span></span></span>
                                      . To opt-out of interest-based advertising by advertisers on our <span
                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                          class="body_text"><span><span><span class="body_text"><span><span><span
                                                      class="body_text">Website</span></span></span></span></span></span></span></span></span>
                                    <span class="body_text">&nbsp;visit</span></span>&nbsp;</span><span
                                  style="color: rgb(48, 48, 241);"><span class="body_text"><a class="link"
                                      href="http://www.aboutads.info/choices/" rel="noopener noreferrer"
                                      target="_blank"><span>http://www.aboutads.info/choices/</span></a></span></span><span><span><span
                                      class="body_text">.
                                      <span><span><span><span><span><span></span></span></span></span></span></span></span></span></span>
                              </p>
                              <p><span><span><strong><u><span class="body_text">Opting out of email
                                          marketing:</span></u>&nbsp;</strong><span class="body_text">You can
                                      unsubscribe from our marketing email list at any time by clicking on the
                                      unsubscribe link in the emails that we send or by contacting us using the
                                      details provided
                                      below. You will then be removed from the marketing email list – however, we may
                                      still communicate with you, for example to send you service-related emails that
                                      are
                                      necessary for the administration and use of your account, to respond to service
                                      requests, or for other non-marketing purposes. To otherwise opt-out, you
                                      may:</span></span></span></p>
                              <p>&nbsp; &nbsp; ■ <span>&nbsp;<span><span class="body_text">Access your account
                                      settings and update your preferences.</span></span></span></p>
                              <p>&nbsp; &nbsp; ■ <span>&nbsp;<span><span class="body_text">Contact us using the
                                      contact information provided. <span><span
                                          class="body_text"><span><span></span></span></span></span></span></span></span>
                              </p>
                              <p style="font-size: 15px; line-height: 1.5;"><br></p>
                              <p id="DNT"><span style="color: rgb(0, 0, 0);"><strong><span
                                      style="font-size: 19px;"><span class="heading_1">12. CONTROLS FOR DO-NOT-TRACK
                                        FEATURES</span></span></strong></span></p>
                              <p><span><span><span class="body_text">Most web browsers and some mobile operating
                                      systems and mobile applications include a Do-Not-Track (“DNT”) feature
                                      or setting you can activate to signal your privacy preference not to have data
                                      about your online browsing activities monitored and collected. At this stage, no
                                      uniform
                                      technology standard for recognizing and implementing DNT signals has been
                                      finalized. As such, we do not currently respond to DNT browser signals or any
                                      other mechanism
                                      that automatically communicates your choice not to be tracked online. If a
                                      standard for online tracking is adopted that we must follow in the future, we
                                      will inform
                                      you about that practice in a revised version of this privacy
                                      notice.</span></span></span></p>
                              <p style="font-size: 15px; line-height: 1.5;"><br></p>
                              <p id="caresidents"><span style="color: rgb(0, 0, 0);"><strong><span
                                      style="font-size: 19px;"><span class="heading_1">13. DO CALIFORNIA RESIDENTS
                                        HAVE
                                        SPECIFIC PRIVACY RIGHTS?</span></span></strong></span></p>
                              <p><span><strong><em><span><span class="body_text">In
                                          Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span
                                        class="body_text">Yes, if
                                        you are a resident of California, you are granted specific rights regarding
                                        access to your personal information.</span></em></span></span></p>
                              <p><span><span><span class="body_text">California Civil Code Section 1798.83, also known
                                      as the “Shine The Light” law, permits our users who are California
                                      residents to request and obtain from us, once a year and free of charge,
                                      information about categories of personal information (if any) we disclosed to
                                      third parties
                                      for direct marketing purposes and the names and addresses of all third parties
                                      with which we shared personal information in the immediately preceding calendar
                                      year. If
                                      you are a California resident and would like to make such a request, please
                                      submit your request in writing to us using the contact information provided
                                      below.</span></span></span></p>
                              <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">If you
                                    are under 18 years of age, reside in California, and have a
                                    registered account with <span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                        class="body_text"><span><span><span class="body_text">the
                                              Website</span></span></span></span></span> , you have the right to
                                    request removal of unwanted data that you publicly post on the <span
                                      style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                        class="body_text"><span><span><span
                                              class="body_text">Website</span></span></span></span></span> . To
                                    request removal of such data, please contact us using the contact information
                                    provided below, and include
                                    the email address associated with your account and a statement that you reside in
                                    California. We will make sure the data is not publicly displayed on the <span
                                      style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                        class="body_text"><span><span><span
                                              class="body_text">Website</span></span></span></span></span> , but
                                    please be aware that the data may not be completely or comprehensively removed
                                    from all our systems (e.g.
                                    backups, etc.). &nbsp;</span> <span class="body_text"></span></span></p>
                            </div>
                            <p style="font-size: 15px; line-height: 1.5;"><span><br></span></p>
                            <p id="policyupdates" style="font-size: 15px; line-height: 1.5;"><span
                                style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                      class="heading_1">14. DO WE MAKE UPDATES TO THIS
                                      NOTICE?</span></span></strong></span></p>
                            <p><span><strong><em><span><span class="body_text">In
                                        Short:</span></span>&nbsp;</em>&nbsp;</strong><span><em><span
                                      class="body_text">Yes, we
                                      will update this notice as necessary to stay compliant with relevant
                                      laws.</span></em></span></span></p>
                            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">We may
                                  update this privacy notice<span>&nbsp;</span>from time to time. The
                                  updated version will be indicated by an updated “Revised” date and the updated
                                  version will be effective as soon as it is accessible. If we make material changes
                                  to this
                                  privacy notice, we may notify you either by prominently posting a notice of such
                                  changes or by directly sending you a notification. We encourage you to review this
                                  privacy notice<span>&nbsp;</span>frequently to be informed of how we are protecting
                                  your information.</span></span></p>
                            <p style="font-size: 15px; line-height: 1.5;"><span><br></span></p>
                            <p id="contact"><span style="color: rgb(0, 0, 0);"><strong><span
                                    style="font-size: 19px;"><span class="heading_1">15. HOW CAN YOU CONTACT US ABOUT
                                      THIS
                                      NOTICE?</span></span></strong></span></p>
                            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">If you
                                  have questions or comments about this notice, you may</span></span>
                              <span><span class="body_text">contact our Data Protection Officer
                                  (DPO),&nbsp;</span></span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                  class="body_text">Alan Hayes ,
                                  by email at alan@irishcelticjewellery.com</span></span> <span class="body_text">,
                                <span>&nbsp;by phone at +353858514764 ,</span></span> <span class="body_text"></span>
                              <span style="color: rgb(89, 89, 89); font-size: 15px;"><span class="body_text">or by post
                                  to:</span></span></p>
                            <div>
                              <span><span><span class="body_text">Jewellery Solutions</span></span>&nbsp; <span
                                  class="body_text"><span><span class="body_text"></span></span></span></span>
                            </div>
                            <div>
                              <span class="body_text"><span><span class="body_text"><span>Alan
                                      Hayes</span></span></span></span>
                            </div>
                            <div>
                              <span class="body_text"><span><span class="body_text"><span>Swords Road, The
                                      Commons</span></span></span></span>
                            </div>
                            <div>
                              <span class="body_text"><span><span
                                    class="body_text"><span>Malahide</span></span></span></span>
                            </div>
                            <div>
                              <span class="body_text"><span><span class="body_text">Dublin</span></span></span>
                            </div>
                            <div>
                              <span><span class="body_text"><span>Ireland</span></span></span>
                              <span><span></span></span> <span class="body_text"><span></span></span>
                              <span><span></span></span>
                            </div>
                            <p id="contact" style="font-size: 15px; line-height: 1.5;"><br></p>
                            <p id="contact"><strong><span class="heading_1">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
                                  DATA WE COLLECT FROM YOU?</span></strong></p>
                            <div>
                              <span class="body_text">Based on the applicable laws of your country, you may have the
                                right to request access to the personal information we collect from
                                you, change that information, or delete it in some circumstances. To request to
                                review, update, or delete your personal information, please visit:
                                privacy@irishcelticjewellery.com . We will respond to your request within 30
                                days.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>