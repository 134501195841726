import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ringSizes } from '../../app.config';
import { environment } from '../../../environments/environment';
import { CartItem } from '../../model/cartItem';
import { Product } from '../../model/product';
import { AuthService } from '../../services/auth.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HeadService } from '../../services/head.service';
import { FavouritesService } from '../../services/favourites.service';
import { ProductService } from '../../services/product.service';
import { TitleService } from '../../services/title.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {

  product$: Observable<Product>;
  ringSizes: Array<string> = ringSizes.ireland;
  ringSize = 'M';

  subscription: Subscription;
  productSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private headService: HeadService,
    private productService: ProductService,
    private titleService: TitleService,
    public auth: AuthService,
    public shoppingCartService: ShoppingCartService,
    public favouritesService: FavouritesService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe(routeParams => {
      if(routeParams.slug) {
        this.product$ = this.productService.fetch(routeParams.slug);
      } else if(routeParams.code) {
        this.product$ = this.productService.fetchByCode(routeParams.code);
      }
      this.productSubscription = this.product$.subscribe(product => {
        this.titleService.set(product.title);
        this.breadcrumbService.setGallery(product.collection, product.type);
        this.headService.createCanonicalLink(product.safeProductUrl);
        this.headService.setMetaDescription(`${product.title} - ${product.description}`);
        this.headService.setProductStructuredData(product);
      });
    });
    this.favouritesService.load();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if(this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
  }

  addToCart(product: Product): void {
    const cartItem = new CartItem(product.code, product);
    if(product.isRing) {
      cartItem.ringSize = this.ringSize;
    }
    this.shoppingCartService.addToCart(cartItem);
  }
}
