import { NgModule, PLATFORM_ID, Injectable, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AdminOrdersComponent } from './components/admin-orders/admin-orders.component';
//import { AdminOrderDetailComponent } from './components/admin-order-detail/admin-order-detail.component';
import { AdminOverviewComponent } from './components/admin-overview/admin-overview.component';
import { CallbackComponent } from './components/callback/callback.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { FavouritesComponent } from './components/favourites/favourites.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { HomeComponent } from './components/home/home.component';
import { MobileMenuService } from './services/mobile-menu.service';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ProductComponent } from './components/product/product.component';
import { ReturnsPolicyComponent } from './components/returns-policy/returns-policy.component';
import { SearchComponent } from './components/search/search.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { StockListComponent } from './components/stock-list/stock-list.component';
import { ManageStockItemComponent } from './components/manage-stock-item/manage-stock-item.component';
import { TermsComponent } from './components/terms/terms.component';

declare let gtag: (a,b,c) => void;

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'collection/:collection/:type', component: GalleryComponent },
  { path: 'item/:code', component: ProductComponent },
  { path: 'product/:slug', component: ProductComponent },
  { path: 'search', component: SearchComponent },
  { path: 'logged-in/callback', component: CallbackComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'favourites', component: FavouritesComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'returns', component: ReturnsPolicyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'shopping-cart', component: ShoppingCartComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'admin/overview', component: AdminOverviewComponent, canActivate: [AuthGuard] },
  { path: 'admin/orders', component: AdminOrdersComponent, canActivate: [AuthGuard] },
  // { path: 'admin/order/:orderNumber', component: AdminOrderDetailComponent, canActivate: [AuthGuard] },
  { path: 'admin/stock-list', component: StockListComponent, canActivate: [AuthGuard] },
  { path: 'admin/stock-item/:code', component: ManageStockItemComponent, canActivate: [AuthGuard] },
  { path: 'private', component: HomeComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    // "initialNavigation" causes warning: 'Navigation triggered outside Angular zone, did you forget to call 'ngZone.run()'?'
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public router: Router,
    public mobileMenuService: MobileMenuService
  ) {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.mobileMenuService.closeMenu();
        if (isPlatformBrowser(this.platformId)) {
          window.scroll(0,0);
          gtag('config', 'UA-710559-3', { page_path: event.urlAfterRedirects });
        }
      }
    });
  }
}
