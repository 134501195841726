<header style="height: auto;">

  <app-top-bar></app-top-bar>

  <div class="container">
    <div class="row">
      <div class="mobile-menu-button col-3 d-lg-none">
        <span class="fas fa-bars pointer" (click)="mobileMenuService.openMenu()"></span>
      </div>
      <div class="logo col-6 col-lg-3">
        <a class="logo" routerLink="/" aria-label="Home">
          <img src="/assets/images/logos/logo-green-silver.svg" alt="Irish Celtic Jewellery" />
        </a>
      </div>
      <div class="search-button col-3 d-lg-none">
        <span (click)="mobileSearchBoxShown = !mobileSearchBoxShown; focusSearch()">
          <span class="fas fa-search" [hidden]="mobileSearchBoxShown"></span>
          <span class="fas fa-times" [hidden]="!mobileSearchBoxShown"></span>
        </span>
      </div>
      <div class="header-menu d-none d-lg-inline-block col">
        <ul class="nav justify-content-end">
          <app-shop-menu></app-shop-menu>
          <li *ngIf="!auth.isAdmin" class="nav-item dropdown" (mouseenter)="aboutUsMenuShown=true"
            (mouseleave)="aboutUsMenuShown=false">
            <span class="nav-link">About Us</span>
            <ul class="dropdown-menu row" [hidden]="showFixedHeader || !aboutUsMenuShown">
              <li><a routerLink="/about-us">About Us</a></li>
              <li><a routerLink="/contact-us">Contact Us</a></li>
              <!-- <li><a routerLink="/delivery-information">Delivery Information</a></li>
              <li><a routerLink="/choosing-a-ring">Choosing a Ring</a></li> -->
              <li><a routerLink="/returns">Returns Policy</a></li>
              <li><a routerLink="/privacy">Privacy</a></li>
              <li><a routerLink="/disclaimer">Disclaimer</a></li>
              <li><a routerLink="/cookie-policy">Cookie Policy</a></li>
              <li><a routerLink="/terms">Terms &amp; Conditions</a></li>
            </ul>
          </li>
          <li *ngIf="!auth.isAdmin" class="nav-item">
            <a href="https://blog.irishcelticjewellery.com/" class="nav-link">Blog</a>
          </li>

          <li *ngIf="!auth.isAdmin" class="nav-item">
            <a class="nav-link" routerLink="/favourites">Favourites <i class="fas fa-heart"></i></a>
          </li>
          <li *ngIf="!auth.isAdmin" class="nav-item">
            <div ngbDropdown #myDrop1="ngbDropdown" class="dropdown cart-dropdown"
              *ngIf="shoppingCartService.shoppingCart$ | async as shoppingCart"
              (mouseenter)="$event.stopPropagation(); myDrop1.open();" 
              (mouseleave)="$event.stopPropagation(); myDrop1.close();">
              <a ngbDropdownToggle routerLink="/shopping-cart" class="nav-link">Cart <i class="fas fa-shopping-cart"></i>
                <span class="badge">{{shoppingCart.itemsCount}}</span></a>
              <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right shop-cart" (click)="myDrop1.close()">
                <h4>{{shoppingCart.items.length}} item{{ shoppingCart.items.length !== 1 ? 's' : ''}} in shopping cart</h4>
                <div class="items">
                  <li *ngFor="let item of shoppingCart.items; let i = index">
                    <a [routerLink]="item.product.safeProductUrl">
                      <div class="row item">
                        <div class="col-3">
                          <img [src]="item.product.safeImageUrl" [alt]="item.product.title" [appImgSrcSet]="item.product.originalImageUrl" sizes="60px">
                        </div>
                        <div class="col-9 details">
                          <div class="ellipsis">{{item.product.title}}</div>
                          <div class="price">{{item.product.formattedPrice}}</div>
                          <div *ngIf="item.ringSize" class="ring-size">Ring Size: {{item.ringSize}}</div>
                        </div>
                      </div>
                    </a>
                  </li>
                </div>
                <li class="actions">
                  <button type="button" class="btn btn-link" routerLink="/shopping-cart">My Shopping
                    Cart</button>
                  <button type="button" class="btn btn-primary" routerLink="/checkout">Check Out</button>
                </li>
              </ul>
            </div>
          </li>
          <li *ngIf="!auth.isAuthenticated">
            <button class="btn btn-outline-primary login-signup" (click)="auth.login()">Login / Sign Up</button>
          </li>
          <li *ngIf="auth.isAdmin">
            <a routerLink="/admin/stock-list" class="nav-link" [ngClass]="{'selected': currentPage.adminManageStock}">Manage
              Stock</a>
          </li>
          <li *ngIf="auth.isAdmin">
            <a routerLink="/admin/orders" class="nav-link"
              [ngClass]="{'selected': currentPage.adminOrders}">Orders</a>
          </li>
          <li *ngIf="auth.isAdmin">
            <a routerLink="/admin/overview" class="nav-link" [ngClass]="{'selected': currentPage.adminOverview}">Admin</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container" [hidden]="!mobileSearchBoxShown">
    <div class="row">
      <div class="col-12 offset-md-6 col-md-6">
        <div class="input-group search-box">
          <input type="text" #searchInput class="form-control" [(ngModel)]="term" (keyup)="handleKeyUp($event)" placeholder="Search for something...">
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" (click)="search()">&nbsp;Search <span class="fas fa-search"></span>&nbsp;</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fixed" [class.fixed-show]="showFixedHeader">
    <div class="container">
      <div class="row">
        <div class="mobile-menu-button col-3 d-lg-none">
          <span class="fas fa-bars pointer" (click)="mobileMenuService.openMenu()"></span>
        </div>
        <div class="logo col-6 col-lg-3">
          <a class="logo" routerLink="/" aria-label="Home">
            <img src="/assets/images/logos/logo-green-silver.svg" alt="Irish Celtic Jewellery" />
          </a>
        </div>
        <div class="search-button col-3 d-lg-none">
          <span (click)="mobileSearchBoxShown = !mobileSearchBoxShown; focusSearch()">
            <span class="fas fa-search" [hidden]="mobileSearchBoxShown"></span>
            <span class="fas fa-times" [hidden]="!mobileSearchBoxShown"></span>
          </span>
        </div>
        <div class="header-menu d-none d-lg-inline-block col">
          <ul class="nav justify-content-end">
            <app-shop-menu></app-shop-menu>
            <li *ngIf="!auth.isAdmin" class="dropdown" (mouseenter)="aboutUsMenuShown=true"
              (mouseleave)="aboutUsMenuShown=false">
              <span class="nav-link">About Us</span>
              <ul class="dropdown-menu row" [hidden]="!showFixedHeader || !aboutUsMenuShown">
                <li><a routerLink="/about-us">About Us</a></li>
                <li><a routerLink="/contact-us">Contact Us</a></li>
                <!-- <li><a routerLink="/delivery-information">Delivery Information</a></li>
                <li><a routerLink="/choosing-a-ring">Choosing a Ring</a></li> -->
                <li><a routerLink="/returns">Returns Policy</a></li>
                <li><a routerLink="/privacy">Privacy</a></li>
                <li><a routerLink="/disclaimer">Disclaimer</a></li>
                <li><a routerLink="/cookie-policy">Cookie Policy</a></li>
                <li><a routerLink="/terms">Terms &amp; Conditions</a></li>
              </ul>
            </li>
            <li *ngIf="!auth.isAdmin">
              <a href="https://blog.irishcelticjewellery.com/" class="nav-link">Blog</a>
            </li>
            <li *ngIf="!auth.isAdmin">
              <a routerLink="/favourites" class="nav-link">Favourites <i class="fas fa-heart"></i></a>
            </li>
            <li *ngIf="!auth.isAdmin">
              <div ngbDropdown #myDrop2="ngbDropdown" class="dropdown cart-dropdown"
                *ngIf="shoppingCartService.shoppingCart$ | async as shoppingCart"
                (mouseenter)="$event.stopPropagation(); myDrop2.open();"
                (mouseleave)="$event.stopPropagation(); myDrop2.close();">
                <a ngbDropdownToggle routerLink="/shopping-cart" class="nav-link">Cart <i
                    class="fas fa-shopping-cart"></i>
                  <span class="badge">{{shoppingCart.itemsCount}}</span>
                </a>
                <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right shop-cart" (click)="myDrop2.close()">
                  <h4>{{shoppingCart.items.length}} item{{ shoppingCart.items.length !== 1 ? 's' : ''}} in my cart</h4>
                  <div class="items">
                    <li *ngFor="let item of shoppingCart.items; let i = index">
                      <a [routerLink]="item.product.safeProductUrl">
                        <div class="row item">
                          <div class="col-3">
                            <img [src]="item.product.safeImageUrl" [alt]="item.product.title" [appImgSrcSet]="item.product.originalImageUrl" sizes="60px">
                          </div>
                          <div class="col-9 details">
                            <div class="ellipsis">{{item.product.title}}</div>
                            <div class="price">{{item.product.formattedPrice}}</div>
                            <div *ngIf="item.ringSize" class="ring-size">Ring Size: {{item.ringSize}}</div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </div>
                  <li class="actions">
                    <button type="button" class="btn btn-link" routerLink="/shopping-cart">Shopping
                      Cart</button>
                    <button type="button" class="btn btn-primary" routerLink="/checkout">Check Out</button>
                  </li>
                </ul>
              </div>
            </li>
            <li *ngIf="!auth.isAuthenticated">
              <button class="btn btn-outline-primary login-signup" (click)="auth.login()">Login / Sign Up</button>
            </li>
            <li *ngIf="auth.isAdmin">
              <a routerLink="/admin/stock-list" class="nav-link"
                [ngClass]="{'selected': currentPage.adminManageStock}">Manage Stock</a>
            </li>
            <li *ngIf="auth.isAdmin">
              <a routerLink="/admin/orders" class="nav-link"
                [ngClass]="{'selected': currentPage.adminOrders}">Orders</a>
            </li>
            <li *ngIf="auth.isAdmin">
              <a routerLink="/admin/overview" class="nav-link"
                [ngClass]="{'selected': currentPage.adminOverview}">Admin</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="container" [hidden]="!mobileSearchBoxShown">
        <div class="row">
          <div class="col-12 offset-md-6 col-md-6">
            <div class="input-group search-box">
              <input type="text" #searchInputFixed class="form-control" [(ngModel)]="term" (keyup)="handleKeyUp($event)" placeholder="Search for something...">
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" (click)="search()">&nbsp;Search <span class="fas fa-search"></span>&nbsp;</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</header>