import { Observable, from } from 'rxjs';
import { tap, retry, debounceTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { environment } from './../../environments/environment';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private toasterService: ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add Authorization header with Access Token if user is logged in and request is to /api/*
    const isApiUrl = request.url.startsWith(environment.api);
    if (this.authService.isAuthenticated && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.accessToken}`
        }
      });
    }
    return next.handle(request).pipe(
      tap(
        event => { },
        error => {
          if (error instanceof HttpErrorResponse) {
            if (error.url.includes('/api/')) {
              this.toasterService.error(error.message, error.name, { closeButton: true });
            }
          }
        }
      ));
  }
}
