<app-breadcrumb></app-breadcrumb>

<section class="mainContent">
  <div class="container">
    <div class="row" *ngIf="favouritesService.favourites$ | async as favourites">
      <div class="col-lg-3 col-md-4 " *ngFor="let thumbnail of favourites; let i = index">
        <app-thumbnail [thumbnail]="thumbnail" [index]="i"></app-thumbnail>
      </div>
    </div>
  </div>
</section>