import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PLATFORM_ID, Injectable, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, share, distinct, distinctUntilChanged } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { formatPercent, formatPrice, applyPriceFormatting } from '../utils';
import { ShoppingCart } from '../model/shoppingCart.js';
import { CartItem } from '../model/cartItem.js';
import { GoogleAnalyticsService } from './google-analytics.service';
import { Product } from '../model/product';

@Injectable({ providedIn: 'root' })
export class ShoppingCartService {

  _shoppingCart$: Observable<ShoppingCart>;
  shoppingCart$ = new BehaviorSubject<ShoppingCart>(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private http: HttpClient,
    private toasterService: ToastrService,
    private logger: NGXLogger,
    private googleAnalyticsService: GoogleAnalyticsService
    ) {

      this._shoppingCart$ = this.http.get<ShoppingCart>(this.url()).pipe(
        share(),
        tap((data: ShoppingCart) => {
          data.items.forEach(item => {
            Product.enrich(item.product);
          });
          data.itemsCount = data.items.length;
          data.formattedTotalAmount = formatPrice(data.totalAmount);
          data.formattedSalePercent = formatPercent(data.salePercent);
          this.shoppingCart$.next(data);
        })
      );
    }

  url = (): string => `${environment.api}/v1/shopping-cart`;
  deleteUrl = (cartItemId: string): string => `${environment.api}/v1/shopping-cart/${cartItemId}`;

  get(): void {
    if(isPlatformBrowser(this.platformId)) {
      this._shoppingCart$.subscribe();
    }
  }

  public addToCart(cartItem: CartItem): void {
    this.logger.info('shopping-cart.service.add-to-cart: ' + JSON.stringify(cartItem));
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    this.http.post(this.url(), cartItem, { ...options, responseType: 'text' }).subscribe(data => {
      this.get();
      this.toasterService.success('Item added to your cart', 'Successful!');
      this.googleAnalyticsService.emitEvent('add_to_cart', 'ecommerce', cartItem.product.price);
    });
  }

  public removeFromCart(cartItem: CartItem): void {
    this.logger.info('shopping-cart.service.remove-from-cart: ' + JSON.stringify(cartItem));
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    this.http.delete(this.deleteUrl(cartItem.id), { ...options, responseType: 'text' }).subscribe((data: any) => {
      this.get();
      this.toasterService.success('Item removed from your cart', 'Successful!');
      this.googleAnalyticsService.emitEvent('remove_from_cart', 'ecommerce', cartItem.product.price);
    });
  }

  public setBillingCountry(country: string): void {
    this.logger.info('shopping-cart.service.set-billing-country: ' + JSON.stringify(country));

  }

}
