import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-shop-menu',
  templateUrl: './shop-menu.component.html',
  styleUrls: ['./shop-menu.component.scss']
})
export class ShopMenuComponent implements OnDestroy {

  menuShown = false;
  shopping = false;
  subscription: Subscription;

  firstPromotedItem = {
    safeProductUrl: '/product/14ct-yellow-gold-knot-wedding-ring-rfgd031ynn',
    originalImageUrl: `${environment.image.root}RFGD031YNN.jpg`,
    safeImageUrl: `${environment.image.root}RFGD031YNN.jpg?tr=w-100,h-100`,
    title: '14ct yellow gold knot wedding ring'
  };

  secondPromotedItem = {
    safeProductUrl: '/product/house-of-lor-silverrose-gold-cz-huggie-earrings-1-bar-on-each-made-from-rare-irish-gold-h30002',
    originalImageUrl: `${environment.image.root}H30002.jpg`,
    safeImageUrl: `${environment.image.root}H30002.jpg?tr=w-100,h-100`,
    title: 'House of Lor silver/rose gold cz huggie earrings 1 bar on each made from rare Irish gold'
  };

  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public shoppingCartService: ShoppingCartService
  ) {
    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.menuShown = false;
      }
      if (event instanceof NavigationEnd) {
        this.shopping = event.url.includes('/product') || event.url.includes('/collection');
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
