import { PLATFORM_ID, ViewChild, ElementRef, Inject, Component, OnInit, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { MobileMenuService } from '../../services/mobile-menu.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { SearchService } from '../../services/search.service';
import { sleep } from '../../utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('searchInput') searchInputField: ElementRef;
  @ViewChild('searchInputFixed') searchInputFixedField: ElementRef;
  showFixedHeader = false;
  aboutUsMenuShown = false;
  mobileSearchBoxShown = false;
  term = '';
  currentPage: any = {};

  subscription: Subscription;
  termSubscription: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private router: Router,
    public auth: AuthService,
    public mobileMenuService: MobileMenuService,
    public shoppingCartService: ShoppingCartService,
    public searchService: SearchService
  ) {
    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentPage.adminManageStock = event.url.includes('/admin/stock-list');
        this.currentPage.adminOrders = event.url.includes('/admin/orders');
        this.currentPage.adminOverview = event.url.includes('/admin/overview');
        this.mobileSearchBoxShown = false;
      }
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    this.toggleFixedHeader();
  }

  ngOnInit(): void {
    this.shoppingCartService.get();
    this.termSubscription = this.searchService.term$.subscribe(term => {
      this.term = term;
    });
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    } else {
      console.error(`[header.component.onDestroy] subscription is undefined`);
    }
    if(this.termSubscription) {
      this.termSubscription.unsubscribe();
    } else {
      console.error(`[header.component.onDestroy] termSubscription is undefined`);
    }
  }

  search(): void {
    this.searchService.navigate(this.term);
  }

  handleKeyUp($event): void {
    if($event.keyCode === 13){
      this.search();
    }
  }

  public focusSearch = async function(): Promise<void> {
    if(this.mobileSearchBoxShown) {
      if(this.showFixedHeader) {
        await sleep(200);
        this.searchInputFixedField.nativeElement.focus();
      } else {
        await sleep(200);
        this.searchInputField.nativeElement.focus();
      }
    }
  };

  private toggleFixedHeader = function(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.pageYOffset > 125) {
        this.showFixedHeader = true;
      } else if (window.pageYOffset <= 35) {
        this.showFixedHeader = false;
      }
    }
  };
}
