import { Component, Input, OnInit } from '@angular/core';
import { Thumbnail } from '../../interfaces/Thumbnail';
import { FavouritesService } from '../../services/favourites.service';
import { ImgSrcSetDirective } from '../../directives/img-src-set.directive';

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent implements OnInit {

  @Input() thumbnail: Thumbnail;
  @Input() index: number;

  constructor(
    public favouritesService: FavouritesService
  ) {
  }

  ngOnInit(): void {
    this.favouritesService.load();
    console.log(`thumbnail`, this.thumbnail);
  }

  addToFavourites(): void {
    this.favouritesService.add(this.thumbnail.code);
  }

  removeFromFavourites(): void {
    this.favouritesService.remove(this.thumbnail.code);
  }
}
