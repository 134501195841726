import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PLATFORM_ID, Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CookiePolicyService {

  _cookiePolicy: Record<string, unknown>;
  _showCookiePopup = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private http: HttpClient,
  ) { }

  cookiePolicyUrl = (): string => `${environment.api}/v1/cookie-policy`;

  public get showCookiePopup(): boolean {
    return this._showCookiePopup;
  }

  accept(): void {
    this._showCookiePopup = false;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('cookie-policy-accepted', new Date().toUTCString());
    }
  }

  close(): void {
    this._showCookiePopup = false;
  }

  check(): void {
    if (isPlatformBrowser(this.platformId)) {
      const cookiePolicyAccepted = localStorage.getItem('cookie-policy-accepted');
      if(!cookiePolicyAccepted) {
        this._showCookiePopup = true;
      }
    }
    // this.http.get<any>(this.cookiePolicyUrl()).subscribe(data => {
    //   this._cookiePolicy = data;
    // });
  }
}
